import React, {useContext, useEffect, useState} from 'react';
import {addSubmission, db, fetchQuestions, getStoredQuestions} from "./db";
import Logo from './assets/images/logo.png'
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {SurveyContext} from "./surveyContext";
import axios from "axios";
import {MaxParticipantContext} from "./maxParticipantContext";
import AdPopup from "./ad-popup";
import {useModalStore} from "./store/modalStore";

const useOnline = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return isOnline;
};


const SurveyForm = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email')
    const code_parrain = searchParams.get('parrain')
    const [questions, setQuestions] = useState([]);
    const [activeQuestion, setActiveQuestion] = useState(0)
    const {nextUrl, setNextUrl} = useModalStore()
    const [selectedAnswer, setSelectedAnswer] = useState([])
    const [showResult, setShowResult] = useState(false)
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null)
    const {setSurveyCompleted} = useContext(SurveyContext);
    const [result, setResult] = useState({
        score: 0, correctAnswers: 0, wrongAnswers: 0,
    })
    const [selectedOptions, setSelectedOptions] = useState([]);
    const {setMaxParticipant, maxParticipant} = useContext(MaxParticipantContext);

    const toggleOption = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter((item) => item !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    const isOnline = useOnline();

    useEffect(() => {
        if (!email) {
            navigate({
                pathname: '/',
            });
        }

        console.log(isOnline)
        const fetchSurveyQuestions = async () => {
            if (isOnline) {
                await fetchQuestions(email);
            }

            const storedQuestions = await getStoredQuestions();
            console.log(storedQuestions)
            setQuestions(storedQuestions);
        };

        fetchSurveyQuestions();
    }, [email, isOnline, navigate]);

    const questionObject = questions[0]?.questions[activeQuestion]

    const onClickNext = () => {
        setSelectedAnswerIndex(null)
        setResult((prev) => selectedAnswer ? {
            ...prev, score: prev.score + 5, correctAnswers: prev.correctAnswers + 1,
        } : {...prev, wrongAnswers: prev.wrongAnswers + 1})
        if (activeQuestion !== questions[0]?.questions?.length - 1) {
            setActiveQuestion((prev) => prev + 1)
        } else {
            setActiveQuestion(0)
            setShowResult(true)
        }
    }

    const onAnswerSelected = (question, answer, type) => {
        setSelectedAnswer((prevSelectedOptions) => {
            const existingOption = prevSelectedOptions.find((item) => item.question === question);
            if (existingOption) {
                return prevSelectedOptions.map((item) => {
                    if (item.question === question) {
                        return {
                            ...item, options: [...item.options, answer],
                        };
                    }
                    return item;
                });
            } else {
                return [...prevSelectedOptions, {
                    question, options: [answer],
                },];
            }
        });

        setSelectedAnswerIndex(true)
    }


    const addLeadingZero = (number) => (number > 9 ? number : `0${number}`)

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log("Handle submit")

        if (!isOnline) {
            let surveyId = questions[0]?.id
            addSubmission({surveyId, email, selectedAnswer, code_parrain});
            // toast.error('Formulaire soumis hors ligne. Il sera synchronisé lorsque le réseau sera disponible.');
            setMaxParticipant(maxParticipant - 1)
            setSurveyCompleted(true)
            navigate({
                pathname: '/thanks'
            });
            return;
        }

        try {
            const body = {
                email: email,
                selectedAnswer: selectedAnswer,
                code_parrain: code_parrain,
                surveyId: questions[0]?.id
            }
            const response = await axios.post('https://admin-draw.masscom-ci.com/api/submit', body)

            console.log("Response")
            console.log(response)

            if (response?.data?.status) {
                // toast.success('Félicitations ! Vous pouvez à présent accéder aux vidéos / Playlists');
                setSurveyCompleted(true)
                setMaxParticipant(maxParticipant - 1)
                setNextUrl('/thanks')
                navigate({
                    // pathname: '/advertisement'
                    pathname: '/thanks'
                });
            } else {
                // toast.error("Accédez aux vidéos /Playlists")
                setSurveyCompleted(true)
                setNextUrl('/thanks')
                navigate({
                    // pathname: '/advertisement'
                    pathname: '/thanks'
                });
            }
        } catch (error) {
            console.log(error?.response?.data?.message)
            // toast.error(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        const fetchMaxPart = async () => {
            const maxPart = await db.maxParticipant.toArray();

            console.log("Max part")
            console.log(maxPart)

            setMaxParticipant(maxPart[0]?.maxPart)
        };

        fetchMaxPart()
    }, [])

    return (<>
        {maxParticipant <= 0 ? <div>
            <nav style={{textAlign: "center"}}>
                <img src={Logo} style={{width: '200px', height: "200px", margin: "auto"}} alt={"logo"}/>
            </nav>

            <div className={"text-center"}>
                <p>There is no active survey please wait for survey activation.</p>
                <Link to={'/video'}>
                    <button className={"py-1 bg-indigo-400 px-2 mt-3 rounded-xl"}>
                        Accédez aux vidéos /Playlists
                    </button>
                </Link>
            </div>
        </div> : <div style={{
            width: '100vw',
            height: '100vh',
            backgroundPosition: "center",
            backgroundImage: `url(${questions[0]?.company_bg})`
        }}>
            <nav style={{textAlign: "center"}}>
                <img src={Logo} style={{width: '200px', height: "200px", margin: "auto"}} alt={"logo"}/>
            </nav>
            <div className="quiz-container">
                {<div className={"text-left"}>
                    <div className={"companyLogo"} style={{textAlign: 'center'}}>
                        <h1 className={"font-bold text-2xl"}>
                            {questions[0]?.survey_title}
                        </h1>
                        <img src={questions[0]?.company_logo} width={"60px"} alt={"Company logo"}/>

                        <div className={"text-left"}>
                            {questionObject?.type === 'MCQ' ?
                                <span style={{
                                    fontSize: "20px",
                                    fontWeight: "bolder",
                                }}>Veuillez sélectionner une option</span> : questionObject?.type === 'MAQ' ?
                                    <span style={{
                                        fontSize: "20px",
                                        fontWeight: "bolder",
                                    }}>Vous pouvez sélectionner plusieurs options</span> :
                                    <span style={{
                                        fontSize: "20px",
                                        fontWeight: "bolder",
                                    }}>Veuillez écrire votre réponse dans la zone de saisie</span>}
                        </div>

                    </div>
                    <div>
                        <span className="active-question-no">{addLeadingZero(activeQuestion + 1)}</span>
                        <span className="total-question">/{addLeadingZero(questions[0]?.questions?.length)}</span>
                    </div>

                    <h2 className={"text-left"}>{questionObject?.question}</h2>
                    {questionObject?.type === 'input' ? <div>
                            <input type={"text"} onChange={(e) => onAnswerSelected(questionObject?.id, e.target.value)}/>
                        </div> :
                        questionObject?.type === 'MCQ' ?
                            questionObject?.options.map((answer, index) => (
                                <div className="flex items-center mb-4">
                                    <input
                                        onChange={(e) => onAnswerSelected(questionObject?.id, parseInt(e.target.value), 'MCQ')}
                                        id={`default-radio-${answer?.id}`} type="radio" value={answer?.id}
                                        name="default-radio"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label htmlFor={`default-radio-${answer?.id}`}
                                           className="ml-2 text-sm font-medium">{answer?.option.split('_')[0]}</label>
                                </div>
                            ))
                            : <ul>
                                {questionObject?.options.map((answer, index) => (
                                    <li
                                        onClick={() => {
                                            onAnswerSelected(questionObject?.id, answer?.id, index)
                                            toggleOption(answer?.option)
                                        }}
                                        key={index}
                                        className={selectedOptions.includes(answer?.option) ? 'selected-answer' : ''}
                                    >
                                        {answer?.option.split('_')[0]}
                                    </li>
                                ))}
                            </ul>}
                    <div className="flex-right">
                        <button
                            onClick={activeQuestion === questions[0]?.questions?.length - 1 ? handleSubmit : onClickNext}
                            disabled={selectedAnswerIndex === null}>
                            {activeQuestion === questions[0]?.questions?.length - 1 ? 'Valider ma participation' : 'Next'}
                        </button>
                    </div>
                </div>}
            </div>
            {/* <AdPopup/> */}
        </div>

        }
    </>)
};

export default SurveyForm;
