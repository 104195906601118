import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Logo from "./assets/images/logo.png";
import {SurveyContext} from "./surveyContext";
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import {db, fetchQuestions, getStoredQuestions, syncSubmissions} from "./db";

const useOnline = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };


    }, []);

    return isOnline;
};

const closeTab = () => {
    console.log("close")
    window.location.href = "about:blank";
  };


const ThanksPage = () => {
    const navigate = useNavigate();
    const {setSurveyCompleted} = useContext(SurveyContext);
    const {control, register, handleSubmit, formState: {errors}} = useForm();
    const [savedEmails, setSavedEmails] = useState([]);
    const [questions, setQuestions] = useState([]);
    const isOnline = useOnline();
    const [maxParticipant, setMaxParticipant] = useState(0)

    return (
        <>
            {
                <div style={{
                    width: '100vw',
                    height: '100vh',
                    backgroundPosition: "center",
                    backgroundImage: `url(${questions[0]?.company_bg})`
                }}>
                    <nav style={{textAlign: "center"}}>
                        <img src={Logo} style={{width: '200px', height: "200px", margin: "auto"}} alt={"logo"}/>
                    </nav>

                    <div className="quiz-container text-center w-100" >
                        <h3 style={{
                                textTransform: "uppercase",
                                fontWeight: "bolder",
                                lineHeight: "2rem",
                                fontSize: "22px",
                                textAlign: "left",
                            }}>MERCI D'AVOIR PARTICIPÉ, VOUS SEREZ APPELÉ UNE FOIS VOTRE NUMÉRO TIRÉ AU SORT.</h3>


                    
                        <img src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExeGE0M2s3bDFxZmFhaWp5bHRlcjYxcHc0dXZqemt3aDZxOXo0bHd6ZiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/UiKXAuHeLhM7C/giphy.gif" className={"mt-4"} style={{width: '50%', margin: "0 auto"}} alt={"gif"}/>

                        <a href="#" onClick={closeTab} className="btn btn-primary btn-block mt-3" style={{width: '50%', fontSize: '24px', fontWeight: 'bolder'}}>Terminer</a>

                        {/* <div className={"mt-4"} style={{textAlign:'center', width: '100%'}}>
                                    <a href="#" className={"gagnant-btn"} style={{ fontSize: '22px', fontWeight: 'bolder'}}>Consulter les gagnants</a>
                        </div> */}
                    </div>
                </div>
                    
            }
        </>
    )
}

export default ThanksPage