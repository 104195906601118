import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {useEffect, useState} from "react";
import {addAdvertisementView, fetchLatestAdvertisement, syncAdvertisementViews} from "./db";
import {useQuery} from "@tanstack/react-query";
import {useModalStore} from "./store/modalStore";
import {useNavigate} from "react-router-dom";

const style = {
    position: 'absolute',
    // top: '50%',
    // left: '50%',
    width: '100vw',
    height: '80vh',
    // transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

const AdPopup = ({setAdPopupOpen}) => {
    const {nextUrl} = useModalStore()
    const navigate = useNavigate()
    const {isPending, isError, data, error} = useQuery({
        queryKey: ['advertisement'],
        queryFn: fetchLatestAdvertisement
    })
    const [canBeSkipped, setCanBeSkipped] = useState(false)

    let cachedAd = "advertisement-example.mp4"

    useEffect(() => {
        setCanBeSkipped(false)
        setTimeout(() => {
            setCanBeSkipped(true)
        }, 10000)
        syncAdvertisementViews().then(r => r)
    }, []);

    useEffect(() => {
        if (data) {
            addAdvertisementView(data)
        }
    }, [data])

    const handleSkip = () => {
        console.log("skip")
        console.log(nextUrl)
        
        if (nextUrl) {
            navigate(nextUrl)
        }
        if (setAdPopupOpen) {
            setAdPopupOpen(false)
        }
    }

    if (isPending) {
        return <span>Loading...</span>
    }

    console.log(data.link ?? cachedAd)
    console.log("PUB")
    console.log(data)

    return (
        <div style={{position: "absolute", top: 0, left: 0, backgroundColor: "black", width: "100vw", height: "100vh"}}>
            {
                canBeSkipped ?
                    <div className={"text-right mb-3"}
                         style={{
                             backgroundColor: "black",
                             position: "absolute",
                             zIndex: "999",
                             right: "5%",
                             bottom: "10%",
                             color: "white",
                             padding: "10px",
                             display: "flex",
                             alignContent: "center",
                             justifyContent: "center",
                         }}>
                        <button onClick={handleSkip}>Passer la publicité<CloseIcon/></button>
                    </div>
                    :
                    null
            }
            <video autoPlay controls style={{width: "100%", height: "100%"}}>
                <source src={`advertisement/${data.link ?? cachedAd}`} type="video/mp4"/>
            </video>
        </div>
    )
}

export default AdPopup
