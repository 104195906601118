import React from 'react';

const AudioModal = ({ audioUrl, onClose }) => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70">
            <div className="bg-white p-4 rounded-lg relative">
                <button
                    className="absolute top-2 right-2 text-xl text-gray-600 hover:text-gray-900"
                    onClick={onClose}
                >
                    &#10005;
                </button>
                <audio controls>
                    <source src={audioUrl} type="audio/mp3" />
                    Your browser does not support the audio element.
                </audio>
            </div>
        </div>
    );
};

export default AudioModal;
