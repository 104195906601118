import React, { createContext, useState } from 'react';

export const SurveyContext = createContext();

export const SurveyProvider = ({ children }) => {
    const [surveyCompleted, setSurveyCompleted] = useState(false);

    return (
        <SurveyContext.Provider value={{ surveyCompleted, setSurveyCompleted }}>
            {children}
        </SurveyContext.Provider>
    );
};