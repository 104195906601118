import React, {useContext, useEffect, useState} from 'react';
import {SurveyContext} from "./surveyContext";
import {fetchQuestions, getStoredQuestions, syncSubmissions} from "./db";
import {Folder} from "@mui/icons-material";
import Logo from "./assets/images/logo.png";
import {Link} from "react-router-dom";

export const useOnline = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);


    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };


    }, []);

    return isOnline;
};


const VideoGallery = () => {
    const {surveyCompleted = false} = useContext(SurveyContext);
    const isOnline = useOnline();
    const [videos, setVideos] = useState([]);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        if (isOnline) {
            syncSubmissions();
        }
    }, [isOnline]);

    useEffect(() => {
        async function fetchCachedVideo() {
            const cacheName = 'my-video-cache'; // Replace with your cache name from the service worker
            const videoUrl1 = '/videos/video-1.mp4'; // Replace with the actual video file path
            const videoUrl2 = '/videos/video-2.mp4'; // Replace with the actual video file path
            const videoUrl3 = '/videos/video-3.mp4'; // Replace with the actual video file path
            const videoUrl4 = '/videos/video-4.mp4'; // Replace with the actual video file path

            const videos = [
                {
                    url: videoUrl1
                },
                {
                    url: videoUrl2
                },
                {
                    url: videoUrl3
                },
                {
                    url: videoUrl4
                }
            ]
            setVideos(videos)
        }

        fetchCachedVideo();
    }, []);

    useEffect(() => {
        const fetchSurveyQuestions = async () => {
            if (isOnline) {
                await fetchQuestions();
            }
            const storedQuestions = await getStoredQuestions();
            setQuestions(storedQuestions);
        };

        fetchSurveyQuestions();
    }, [isOnline]);
    return (
        <>
            <div>
                {surveyCompleted ?
                    <>
                        <nav style={{textAlign: "center"}}>
                            <img src={Logo} style={{width: '200px', height: "200px", margin: "auto"}} alt={"logo"}/>
                        </nav>
                        <div className={"my-10 text-4xl"}>
                            Multimedia
                        </div>
                        <div className="md:w-[38rem] w-[25rem] theme-structure big-file-manager">
                            <div className={"text-center flex items-center justify-evenly"}>


                                <Link to={'/documentaries'}>
                                    <div
                                        className={"hover:animate-pulse hover:cursor-pointer flex flex-col items-center"}>
                                        <Folder sx={{width: "8rem", height: "8rem", color: "#f6d772"}}/>
                                        <b>Documentaires</b>
                                    </div>
                                </Link>

                                <Link to={'/humour'}>
                                    <div
                                        className={"hover:animate-pulse hover:cursor-pointer flex flex-col items-center"}>
                                        <Folder sx={{width: "8rem", height: "8rem", color: "#f6d772"}}/>
                                        <b>Humour</b>
                                    </div>
                                </Link>

                                <Link to={'/video-clips'}>
                                    <div
                                        className={"hover:animate-pulse hover:cursor-pointer flex flex-col items-center"}>
                                        <Folder sx={{width: "8rem", height: "8rem", color: "#f6d772"}}/>
                                        <b>Videos Clips</b>
                                    </div>
                                </Link>

                                <Link to={'/music'}>
                                    <div
                                        className={"hover:animate-pulse hover:cursor-pointer flex flex-col items-center"}>
                                        <Folder sx={{width: "8rem", height: "8rem", color: "#f6d772"}}/>
                                        <b>Musique</b>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </>
                    : <div className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}>
                        <nav style={{textAlign: "center"}}>
                            <img src={Logo} style={{width: '200px', height: "200px", margin: "auto"}} alt={"logo"}/>
                        </nav>
                        <div className={"px-5 py-4 text-center bg-amber-50 rounded-2xl"}>
                            <p>
                                POUR ACCÉDER AUX VIDEOS/MUSIQUE MERCI DE PRENDRE 5 SECONDES POUR REPONDRE A UNE QUESTION
                            </p>
                            <div>
                                <Link to={'/'}>
                                    <button className={"bg-indigo-400 rounded-2xl px-3 py-2 my-4 text-white"}>
                                        Accéder aux videos
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                }

            </div>

        </>

    );
};

export default VideoGallery;
