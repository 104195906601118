import React, {useContext, useEffect, useState} from "react";
// import {Link, useNavigate} from "react-router-dom";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Logo from "./assets/images/logo.png";
import {SurveyContext} from "./surveyContext";
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import {db, fetchQuestions, getStoredQuestions, syncSubmissions} from "./db";

const useOnline = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };


    }, []);

    return isOnline;
};

const closeTab = () => {
    console.log("close")
    window.location.href = "about:blank";
  };




const ThanksParrain = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const code_parrain = searchParams.get('parrain')
    const {setSurveyCompleted} = useContext(SurveyContext);
    const {control, register, handleSubmit, formState: {errors}} = useForm();
    const [savedEmails, setSavedEmails] = useState([]);
    const [questions, setQuestions] = useState([]);
    const isOnline = useOnline();
    const [maxParticipant, setMaxParticipant] = useState(0)

    const backHome = () => {
        navigate({
            pathname: '/'
        });
    }

    return (
        <>
            {
                <div style={{
                    width: '100vw',
                    height: '100vh',
                    backgroundPosition: "center",
                    backgroundImage: `url(${questions[0]?.company_bg})`
                }}>
                    <nav style={{textAlign: "center"}}>
                        <img src={Logo} style={{width: '200px', height: "200px", margin: "auto"}} alt={"logo"}/>
                    </nav>

                    <div className="quiz-container text-center w-100" >
                        <h3 style={{
                                textTransform: "uppercase",
                                fontWeight: "bolder",
                                lineHeight: "2rem",
                                fontSize: "22px",
                                textAlign: "left",
                            }}>VOTRE PROFIL A ETE CREE AVEC SUCCEES.
                            <br/>
                           
                             VOUS POUVEZ DESORMAIS PARTAGER VOTRE CODE DE PARRAINAGE AVEC VOS AMIS.
                             </h3>


                             <p style={{
                                            fontSize: "26px",
                                            textAlign: "center",
                                        }}>
                                            {/* Votre code de parrainage est :   */}
                                            {/* <br/> */}
                                            <span style={{
                                                fontSize: "50px",
                                                fontWeight: "bolder",
                                                // background: "#ffff00a3",
                                            }}> {code_parrain} </span>
                                            <br/>
                                            <span style={{
                                                textDecoration: "underline",
                                                fontWeight: "bolder",
                                                // background: "#ffff00a3",
                                            }}>Merci de mémoriser votre code</span>
                                        </p>


                    
                        <img src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExeGE0M2s3bDFxZmFhaWp5bHRlcjYxcHc0dXZqemt3aDZxOXo0bHd6ZiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/UiKXAuHeLhM7C/giphy.gif" className={"mt-4"} style={{width: '50%', margin: "0 auto"}} alt={"gif"}/>

                        <a href="#" onClick={closeTab} className="btn btn-primary btn-block mt-3" style={{width: '50%', fontSize: '24px', fontWeight: 'bolder'}}>Terminer</a>
                        <br/>
                        <a href="#" onClick={backHome}  className="btn btn-success btn-block mt-3" style={{width: '50%', fontSize: '24px', fontWeight: 'bolder'}}>Accueil</a>

                        {/* <div className={"mt-4"} style={{textAlign:'center', width: '100%'}}>
                                    <a href="#" className={"gagnant-btn"} style={{ fontSize: '22px', fontWeight: 'bolder'}}>Consulter les gagnants</a>
                        </div> */}
                    </div>
                </div>
                    
            }
        </>
    )
}

export default ThanksParrain